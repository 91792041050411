<template>    
    <v-card>
        <!-- Titulo del dialogo -->
        <v-card-title>
            Agregar Regimen Físcal
        </v-card-title>
        <v-divider></v-divider>
            <!-- Formulario -->
        <v-form ref="form" class="px-4">
            
            <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.regime" label="Régimen Fiscal"></v-text-field>
            </v-col>
            <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.iva_retention" label="Retención IVA" suffix="%"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.isr_retention" label="Retención ISR" suffix="%"></v-text-field>
                </v-col>
            </v-row>
            <v-card-actions class="pt-4">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text :disabled="!valid" @click="save">Guardar</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import axios from "axios";
export default {
    data:()=>({  
        valid: true,
        editedItem: {
            regime:'',
            iva_retention:0,
            isr_retention:0
        },
        rules: {
            required: value => !!value || 'Campo requerido',
        },
    }),
    methods:{
        close () {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.$nextTick(() => {
                this.$emit("closeDialoRegimen", false);
            })
        },
        save () {
            axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/tax_regime",Object.assign(this.editedItem)).then(response=>{
                this.close()
            })
            
        },
    }
}
</script>