

<template>       
    <v-data-table expand-icon :headers="headers" :items="regimenes" class="elevation-0">
        <template slot="no-data">
            No existen estatus registrados.
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:[`item.isr_retention`]="{ item }">
            <span v-if="item.isr_retention!=null">{{item.isr_retention + '%'}}</span>
        </template >
        <template v-slot:[`item.iva_retention`]="{ item }">
            <span v-if="item.iva_retention!=null">{{item.iva_retention + '%'}}</span>
        </template >
        <template v-slot:top>    
            <v-dialog v-model="dialog" max-width="600px">
                <editRegimen @closeDialogRegimen="closeDialogRegimen" v-bind:editedRegimen="editedItem"/>
            </v-dialog> 
        </template>
  </v-data-table>
</template>
<script>
import editRegimen from "../influencer_regimen/edit"
import axios from "axios";
export default {
    components: {
        'editRegimen':editRegimen,
    },
    data:()=>({  
        editedItem:'',
        headers:[
            {text: 'Régimen', value: 'regime'},
            {text: 'Retención ISR', value: 'isr_retention'},
            {text: 'Retención IVA', value: 'iva_retention'},
            {text: 'Acciones', value: 'actions', sortable: false },
        ],
        dialog:false,
    }),
    computed:{
        regimenes:{
            get(){
                return this.$store.state.influencer_regimen.regimenes;
            }
        },
    },
    methods:{
        deleteItem (item) {
            let id = item.id
            if (confirm('¿Seguro que deseas borrar este régimen?')) {
            axios.delete(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/tax_regime/"+id).then(response => {
                this.$store.dispatch('influencer_regimen/getRegimenes')
            });
            }
        },
        edit(item){
            this.editedItem = item
            this.dialog = true
        },
        closeDialogRegimen: function(params) {
            this.dialog = params;
            this.$store.dispatch('influencer_regimen/getRegimenes')
        },
    },
    created(){
        this.$store.dispatch('influencer_regimen/getRegimenes')
    },
}
</script>